import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import ReactDOM from "react-dom";
import Dropdown from 'react-dropdown';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import 'react-dropdown/style.css';

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
let currentuser = "default";
const checkinButtonHere = "btn checkin-button dancer-here";
const checkinButtonMove = "btn checkin-button dancer-move";
const checkinButtonScratch = "btn checkin-button dancer-scratch";
const userMap = new Map();
let currentHeat = "1";

async function get_dancers_for_heat(value) {
    var heat = value.value;
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_all_dancers_for_a_heat_checkin',
            'heat': heat,
            'judge': userMap.get(currentuser)
        })
    }).then(function (response) {
        let data = response.json();
        return data;
    }).then(data => {
        checkAndRemoveElementById("tableContainer");
        const body = document.getElementById("checkinTable");
        const div = document.createElement("div");
        div.id = "tableContainer";
        body.appendChild(div);
        
        if (data.length !== 0) {
            ReactDOM.render(
                <div>
                    {CheckinView.renderCheckinDancerTable(data, heat)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div>
                    Sorry, no heat available {heat}!
                </div>, div
            );
        }
    })
}

async function send_heat_ready_email() {
    showSpinner("CheckinSpinner");
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'send_heat_ready_email',
            'heat': currentHeat
        })
    }).then(response => {
        var element = document.getElementById("emailResponseMessage");
        if (element !== null && element !== undefined) {
            element.innerHTML= "Emails sent for dancers in heat " + currentHeat + "!";
        }
        
        hideSpinner("CheckinSpinner"); 
    }).catch((error)=>{        
        hideSpinner("CheckinSpinner"); 
        console.debug(error);
    });       
}

async function confirm_dancer(e, dancer) {
    showSpinner("CheckinSpinner");

    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'update_here',
            'bib_number': dancer.bib_number,
            'here': 'yes'
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data === 200) {
            confirmDancerRow(dancer.bib_number);
        }
        hideSpinner("CheckinSpinner");
    }).catch((error) => {
        hideSpinner("CheckinSpinner");
    });
}

async function change_dancer_heat(e, dancer) {
    showSpinner("CheckinSpinner");
    var newHeat = (Number(dancer.heat) + 1).toString();

    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'change_dancer_heat',
            'bib_number': dancer.bib_number,
            'new_heat': newHeat
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data === 200) {
            hideDancerRow(dancer.bib_number);
        }
        hideSpinner("CheckinSpinner");
    }).catch((error) => {
        hideSpinner("CheckinSpinner");
    })
}

async function scratch_dancer(e, dancer) {
    showSpinner("CheckinSpinner");

    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'mark_scratched',
            'bib_number': dancer.bib_number,
            'undo': false
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        scratchDancerRow(dancer.bib_number);
        hideSpinner("CheckinSpinner");
    }).catch((error) => {
        hideSpinner("CheckinSpinner");
    });
}

async function get_one_judge_placements_for_dancer() {
    showSpinner("CheckinSpinner");
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_one_judge_placements_for_dancer',
            'bib_number': this.state.bibNumSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("bib-search-data-container");
        const body = document.getElementById("dancerDetail");
        const div = document.createElement("div");
        div.id = "bib-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="bib-search-data">
                    {CheckinView.renderDancerDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="bib-search-data">
                    Sorry, dancer "{this.state.bibNumSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("CheckinSpinner");
    }).catch((error) => {
        hideSpinner("CheckinSpinner");
        this.setState({ calcFailure: true });
    });
}

async function get_one_judge_placements_for_dancer_by_name() {
    showSpinner("CheckinSpinner");
    
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_one_judge_placements_for_dancer_by_name',
            'name': this.state.nameSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("name-search-data-container");
        const body = document.getElementById("dancerNameDetail");
        const div = document.createElement("div");
        div.id = "name-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="name-search-data">
                    {CheckinView.renderDancerDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="name-search-data">
                    Sorry, dancer named "{this.state.nameSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("CheckinSpinner");
    }).catch((error) => {
        hideSpinner("CheckinSpinner");
        this.setState({ calcFailure: true });
    });
}

function showSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.remove("hide");
}

function hideSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.add("hide");
}
function checkAndRemoveElementById(id) {
    let previousDancerDiv = document.getElementById(id);
    if (previousDancerDiv != null) {
        previousDancerDiv.remove();
    }
}

function hideDancerRow(id) {
    var element = document.getElementById(id);
    if (element !== null && element !== undefined) {
        element.parentElement.parentElement.style.display = 'none';
    }
}

function confirmDancerRow(id) {
    var element = document.getElementById(id);
    if (element !== null && element !== undefined) {
        element.parentElement.parentElement.classList.remove("dancer_scratch");
        element.parentElement.parentElement.classList.add("dancer_here");
    }
}

function scratchDancerRow(id) {
    var element = document.getElementById(id);
    if (element !== null && element !== undefined) {
        element.parentElement.parentElement.classList.remove("dancer_here");
        element.parentElement.parentElement.classList.add("dancer_scratch");
    }
}

export default class CheckinView extends React.Component {
    constructor(props) {
        super(props);
        currentuser = this.props.user
        this.state = {
            dancers: [{bib_number:'000'}],
            heat: "1",
            loading: +true,
            bibNumSearch: '',
            nameSearch: '',
            updateOldBib: '',
            updateNewBib: '',
            calcFailure: +false,
            addBibNum: '',
            addDancerName: '',
            addDancerRole: '',
            addHeat: '',
            addPlacement: '',
            changeHeatBib: '',
            changeHeatNew: '',
            overwriteBib: '',
            overwritePlacement: ''
        };

        userMap.set("sherie_502@yahoo.com", "sherie_carranza");
        userMap.set("justin.mann0511@gmail.com", "justin_mann");
        userMap.set("alesandrabull@gmail.com", "alesandra_bull");
        userMap.set("casey.bayer.9@gmail.com", "casey.bayer");
        userMap.set("iadrumman@gmail.com", "noah_price");
    }

    async componentDidMount() {
        const response = await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Connection': 'keep-alive'
            },
            body: JSON.stringify({
                'method_name': 'get_all_dancers_for_a_heat_checkin',
                'heat': '1',
                'judge': 'default'
            })
        })
        const body = await response.json();

        this.setState({ dancers: body, heat: 1, loading: false });
    }

    static renderDancerDetailTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" ></Column>
                    <Column field="dancer_name" header="Name" ></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="heat" header="Heat" sortable></Column>
                    <Column field="here" header="Here?" sortable></Column>
                    <Column field="scratch" header="Scratched?" sortable></Column>
                </DataTable>
            </div>
        );
    }

    static getRowClassName(rowData) {
        if (rowData.here === 'yes') {
            return 'dancer_here';
        } else {
            return '';
        }
    }

    static renderCheckinDancerTableContainer(dancers, dancerHeat) {
        return (
            <div id="checkinTable">
                <div className="heat">
                    <span id="tabelLabel" >Placement Heat: </span>
                    <Dropdown options={options} onChange={get_dancers_for_heat.bind(this)} value={{ label: options[dancerHeat - 1] }} placeholder="Select a heat" />
                    <button className="sendHeatEmailBtn" onClick={send_heat_ready_email}>Send Heat Email</button>
                    <span id="emailResponseMessage"></span>
                </div>
                {CheckinView.renderCheckinDancerTable(dancers, dancerHeat)}
            </div>
        );
    }

    static renderCheckinDancerTable(dancers, dancerHeat) {
        currentHeat = dancerHeat;
        return (
                <div id="tableContainer">
                    <DataTable value={dancers} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex" rowClassName={CheckinView.getRowClassName}>
                        <Column field="bib_number" header="Bib" sortable></Column>
                        <Column field="dancer_name" header="Name"></Column>
                        <Column field="dancer_role" header="Role" sortable></Column>
                        <Column field="placement" header="" body={CheckinView.checkinButtonsBodyTemplate}></Column>
                    </DataTable>
                </div>
        );
    }

    static checkinButtonsBodyTemplate(dancer) {

        return (
            <div id={dancer.bib_number} className="gap-3">
                <button type="button" className={checkinButtonHere} onClick={e => confirm_dancer(e, dancer)}>Here</button>
                <button type="button" className={checkinButtonMove} onClick={e => change_dancer_heat(e, dancer)}>Move</button>
                <button type="button" className={checkinButtonScratch} onClick={e => scratch_dancer(e, dancer)}>Scratch</button>
            </div>
        );
    }

    render() {
        if (userMap.has(currentuser)) {
            let contents = +this.state.loading
                ? <p><em>Loading table data...</em></p>
                : CheckinView.renderCheckinDancerTableContainer(this.state.dancers, currentHeat);

            return (
                <div id="heatContainer">
                    <Accordion multiple>
                        <AccordionTab header="Search for Dancer">
                            <div>
                                <h5>Search by bib number</h5>
                                <label className="adminInputLabel">Bib number:</label>
                                <InputText className="p-inputtext-sm" value={this.state.bibNumSearch} onChange={(e) => this.setState({ bibNumSearch: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={get_one_judge_placements_for_dancer.bind(this)}>Search</button>

                                <div id="dancerDetail">
                                </div>

                                <hr />

                                <h5>Search by dancer name</h5>
                                <label className="adminInputLabel">Dancer Name:</label>
                                <InputText className="p-inputtext-sm" value={this.state.nameSearch} onChange={(e) => this.setState({ nameSearch: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={get_one_judge_placements_for_dancer_by_name.bind(this)}>Search</button>

                                <div id="dancerNameDetail">
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>

                    {contents}

                    <div id="CheckinSpinner" className="spinner-parent hide">
                        <div className="spinner-container justify-content-center">
                            <ProgressSpinner />
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className='body-container'>Non-Judges cant see heat info! </div>
            );
        }
    }
}
