/* src/App.js */
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import React from 'react' //, { useEffect, useState }
import { Amplify } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'; //, Button, Heading 
import '@aws-amplify/ui-react/styles.css';
import Home from './Home';
import BadgeCreate from './BadgeCreate';
import HeatView from './HeatView';
import CheckinView from './CheckinView';
import Admin from './Admin';
import awsExports from "./aws-exports";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


Amplify.configure(awsExports);

const App = ({ signOut, user }) => {
    return (
        <div curruser={user}>
            <Router>
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand>
                            <img src="/Lindyfest_Logo.png" alt="lindyfest" className="navbar-logo" />
                            <span className="brand-text"> Placements</span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Item><Link className="nav-link" to="/">Home</Link></Nav.Item>
                                {getCheckinLink(user)}
                                {getHeatLink(user)}
                                {getBadgeCreateLink(user)}
                                {getAdminLink(user)}
                                <Nav.Item className="signIn-li">
                                    <p>Hello {user.attributes.email}, <a className="sign-out" onClick={signOut}> Sign out?</a></p>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div className="mainContainer">
                    {/* 
                        -this renders the individual pages
                        -we also need to add a redirect for every new page into the AWS Amplify under All apps > trackplacements > App settings: Rewrites and redirects                    
                    */}
                    <Routes>
                        <Route exact path='/' element={< Home />}></Route>
                        <Route exact path='/HeatView' element={< HeatView user={user.attributes.email} />}></Route>
                        <Route exact path='/HeatView/' element={< HeatView user={user.attributes.email} />}></Route>
                        <Route exact path='/BadgeCreate' element={< BadgeCreate user={user.attributes.email} />}></Route>
                        <Route exact path='/BadgeCreate/' element={< BadgeCreate user={user.attributes.email} />}></Route>
                        <Route exact path='/CheckinView' element={< CheckinView user={user.attributes.email} />}></Route>
                        <Route exact path='/CheckinView/' element={< CheckinView user={user.attributes.email} />}></Route>
                        <Route exact path='/Admin' element={< Admin user= {user.attributes.email} />}></Route>
                        <Route exact path='/Admin/' element={< Admin user= {user.attributes.email} />}></Route>
                        <Route exact path='*' element={< Home />}></Route>
                    </Routes>
                </div>
            </Router>

        </div>
    );
}

function getBadgeCreateLink(user) {
    let badgeCreateUsers = "sherie_502@yahoo.com,justin.mann0511@gmail.com,alesandrabull@gmail.com,casey.bayer.9@gmail.com,iadrumman@gmail.com,crystalembrace@gmail.com";

    if (user.attributes && badgeCreateUsers.includes(user.attributes.email)) {
        return (
            <Nav.Item><Link className="nav-link" to="/BadgeCreate" >Badge Create</Link></Nav.Item>
        );
    }
}

function getCheckinLink(user) {
    let badgeCreateUsers = "sherie_502@yahoo.com,justin.mann0511@gmail.com,alesandrabull@gmail.com,casey.bayer.9@gmail.com,iadrumman@gmail.com";

    if (user.attributes && badgeCreateUsers.includes(user.attributes.email)) {
        return (
            <Nav.Item><Link className="nav-link" to="/CheckinView" >Checkin</Link></Nav.Item>
        );
    }
}

function getHeatLink(user) {
    let badgeCreateUsers = "sherie_502@yahoo.com,justin.mann0511@gmail.com,alesandrabull@gmail.com,jfoote226@hotmail.com,flattoplindyhop@gmail.com,joanna@joannalucero.com,lucero.joanna@gmail.com,Andreacgordon@me.com";
    if (user.attributes && badgeCreateUsers.includes(user.attributes.email)) {
        return (
            <Nav.Item><Link className="nav-link" to="/HeatView" >Heats</Link></Nav.Item>
        );
    }
}

function getAdminLink(user) {
    let badgeCreateUsers = "sherie_502@yahoo.com,justin.mann0511@gmail.com,alesandrabull@gmail.com,casey.bayer.9@gmail.com,iadrumman@gmail.com,crystalembrace@gmail.com";

    if (user.attributes && badgeCreateUsers.includes(user.attributes.email)) {
        return (
            <Nav.Item><Link className="nav-link" to="/Admin">Admin</Link></Nav.Item>
        );
    }
}
export default withAuthenticator(App);