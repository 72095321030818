import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ReactDOM from "react-dom";
import Dropdown from 'react-dropdown';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import 'react-dropdown/style.css';

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const primary = "btn placement-button";
const clearButton = "btn placement-button clear row-action";
const scratchButton = "btn placement-button scratch row-action";
const hideScratchButton = "hide";
const defaultPlacement = "btn placement-button default";
const finalPlacement = "btn placement-button final";
let currentuser = "default";
const userjudgemap = new Map();

async function savejudgevote(selectedrow, newplacement) {
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'post_judge_placement',
            'partition_key': selectedrow.heat,
            'sort_key': make_sortkey(selectedrow.bib_number, selectedrow.dancer_role, selectedrow.heat, userjudgemap.get(currentuser)),
            'bib_number': selectedrow.bib_number,
            'dancer_name': selectedrow.dancer_name,
            'dancer_role': selectedrow.dancer_role,
            'heat': selectedrow.heat,
            'judge': userjudgemap.get(currentuser),
            'placement': newplacement,
        })
    }).then(response => {
        console.debug(response.status);
    });
}
async function removejudgevote(e, selectedrow) {
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'remove_judge_placement',
            'partition_key': selectedrow.heat,
            'sort_key': make_sortkey(selectedrow.bib_number, selectedrow.dancer_role, selectedrow.heat, userjudgemap.get(currentuser)),
        })
    }).then(response => {
        let elems = document.querySelectorAll(".dancer_" + selectedrow.bib_number + " .final");

        Array.from(elems).forEach(
            (el) => el.classList.remove('final')
          );
        console.debug(response.status);
    });
}

async function get_dancers_for_heat(value) {
    var heat = value.value;
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_all_dancers_for_a_heat',
            'heat': heat,
            'judge': userjudgemap.get(currentuser)
        })
    }).then(function (response) {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("heatTableContainer");
        const body = document.getElementById("heattable");
        const div = document.createElement("div");
        div.id = "heatTableContainer";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div>
                    {HeatView.renderDancerTable(data, heat)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div>
                    Sorry, no heat data available!
                </div>, div
            );
        }
    })
}

function checkAndRemoveElementById(id) {
    let previousDancerDiv = document.getElementById(id);
    if (previousDancerDiv != null) {
        previousDancerDiv.remove();
    }
}

function make_sortkey(bib, role, heat, judge) {
    const sort_key = bib + "_" + role + "_" + heat + "_" + judge;
    return sort_key;
}

async function judge_admin_update_here(dancer) {
    const response = await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'mark_scratched',
            'bib_number': dancer.bib_number,
            'undo': false
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        let element = document.getElementsByClassName("dancer_" + dancer.bib_number)[0];
        element.remove();
        console.debug(response.status);
    }).catch((error) => {
        console.debug(error);
    });
}

export default class HeatView extends Component {
    constructor(props) {
        super(props);
        currentuser = this.props.user
        this.state = { 
            dancers: [], 
            heat: "1", 
            loading: true,
            currentDancerBib: "0"
         };
        userjudgemap.set("sherie_502@yahoo.com", "sherie_carranza");
        userjudgemap.set("jfoote226@hotmail.com", "jerry_foote");
        userjudgemap.set("justin.mann0511@gmail.com", "justin_mann");
        userjudgemap.set("alesandrabull@gmail.com", "alesandra_bull");        
        userjudgemap.set("flattoplindyhop@gmail.com", "aj_howard");
        userjudgemap.set("joanna@joannalucero.com", "joanna_lucero");
        userjudgemap.set("lucero.joanna@gmail.com", "joanna_lucero");
        userjudgemap.set("Andreacgordon@me.com", "andrea_gordon");
    }

    async componentDidMount() {
        const response = await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Connection': 'keep-alive'
            },
            body: JSON.stringify({
                'method_name': 'get_all_dancers_for_a_heat',
                'heat': '1',
                'judge': userjudgemap.get(currentuser)
            })
        })
        const body = await response.json();

        this.setState({ dancers: body, heat: 1, loading: false });
    }

    static renderDancerTableContainer(dancers, currentHeat) {
        return (
            <div id="heattable">
                <div className="heat">
                    <span id="tabelLabel" >Placement Heat: </span>
                    <Dropdown options={options} onChange={get_dancers_for_heat.bind(this)} value={{ label: options[currentHeat - 1] }} placeholder="Select a heat" />
                </div>
                {HeatView.renderDancerTable(dancers, currentHeat)}
            </div>
        );
    }

    static getRowClassName(rowData) {
        // Replace 'some_field' and 'some_value' with your actual field and value
        return "dancer_" + rowData.bib_number;
    }

    static renderDancerTable(dancers) {
        return (
            <div id="heatTableContainer">
                <DataTable value={dancers} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex" rowClassName={HeatView.getRowClassName}>
                    <Column field="bib_number" header="Bib" sortable></Column>
                    <Column field="dancer_name" header="Name"></Column>
                    <Column field="dancer_role" header="Role" sortable></Column>
                    <Column field="placement" header="Placement" body={HeatView.placementButtonsBodyTemplate}>
                    </Column>
                </DataTable>
            </div>
        );
    }
    static placementButtonsBodyTemplate(dancer) {
        return (
            <div className="gap-3">
                <button type="button" className={dancer.judgesplacement === '3' ? finalPlacement : dancer.placement === '3' ? defaultPlacement : primary} onClick={e => HeatView.onChangePlacement(e, dancer)}>3</button>
                <button type="button" className={dancer.judgesplacement === '4' ? finalPlacement : dancer.placement === '4' ? defaultPlacement : primary} onClick={e => HeatView.onChangePlacement(e, dancer)}>4</button>
                <button type="button" className={dancer.judgesplacement === '5' ? finalPlacement : dancer.placement === '5' ? defaultPlacement : primary} onClick={e => HeatView.onChangePlacement(e, dancer)}>5</button>
                <button type="button" className={dancer.judgesplacement === '6' ? finalPlacement : dancer.placement === '6' ? defaultPlacement : primary} onClick={e => HeatView.onChangePlacement(e, dancer)}>6</button>
                <button type="button" className={clearButton} onClick={e => HeatView.onChangePlacement(e, dancer)}>clear</button>
                <button type="button" className={currentuser==="justin.mann0511@gmail.com"? scratchButton : hideScratchButton} onClick={e => HeatView.onChangePlacement(e, dancer)}>scratch</button>
            </div>
        );
    }

    static onChangePlacement(value, dancer) {
        let thisButton = value.currentTarget;        
        if (thisButton.innerHTML === 'clear') {
            removejudgevote(value, dancer);
        }
        else if (thisButton.innerHTML === 'scratch') {  
            judge_admin_update_here(dancer);
        }
        else {
            let parent = thisButton.parentElement;
            let otherButtons = parent.children;
            for (let button in otherButtons) {
                if (button < otherButtons.length && !otherButtons[button].className.includes("row-action")) {
                    if (!otherButtons[button].className.includes("default")) {
                        otherButtons[button].className = "btn placement-button";
                    }
                    else {
                        otherButtons[button].className = "btn placement-button default";
                    }
                }
                else {
                    break;
                }
            }
            if (!thisButton.className.includes("default")) {
                thisButton.className = "btn placement-button final";
            }
            else {
                thisButton.className = "btn placement-button default final";
            }
            let newplacement = thisButton.childNodes[0].data;
            savejudgevote(dancer, newplacement);
        }
    }

    render() {

        if (userjudgemap.has(currentuser)) {
            let contents = this.state.loading
                ? <p><em>Loading table data...</em></p>
                : HeatView.renderDancerTableContainer(this.state.dancers, "1");

            return (
                <div id="heatContainer">
                    {contents}
                </div>
            );
        }
        else {
            return (
                <div className='body-container'>Non-Judges cant see heat info! </div>
            );
        }
    }
}
