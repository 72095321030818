import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from "primereact/inputtext";
import { CSVLink } from "react-csv";
import ReactDOM from "react-dom";
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';

let currentuser = "default";
const useradminmap = new Map();

async function get_final_plaments_all() {
    showSpinner("AdminSpinner");
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_final_plaments_all',
        })
    }).then(response => {
        let data = response.json();
        return data;
    }).then(data => {
        this.setState({ downloadData: data }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLink.current.link.click()
        });

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

async function track_placement_holds_export() {
    showSpinner("AdminSpinner");
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'shakey_track_placement_export',
        })
    }).then(response => {
        let data = response.json();
        return data;
    }).then(data => {
        this.setState({ shakeyDownloadData: data }, () => {
            // click the CSVLink component to trigger the CSV download
            this.shakeyLink.current.link.click()
        });

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

async function calculate_final_placments() {
    showSpinner("AdminSpinner");
    const response = await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'calculate_final_placments',
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data.length !== 0) {
            this.setState({ calcSuccess: true });
        }
        else {
            //this.setState({ calcFailure: true });
        }
        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        //this.setState({ calcFailure: true });
    });
}

async function update_bib_number() {
    showSpinner("AdminSpinner");
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'update_bib_number',
            'bib_number': this.state.updateOldBib,
            'new_bib_number': this.state.updateNewBib
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("bib-update-data-container");
        const body = document.getElementById("updateBibResult");
        const div = document.createElement("div");
        div.id = "bib-update-data-container";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div id="bib-update-data">
                    Bib number updated from {this.state.updateOldBib} to {this.state.updateNewBib}!
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="bib-update-data">
                    Sorry, bib not found!
                </div>, div
            );
        }
        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

async function add_dancer() {
    showSpinner("AdminSpinner");
    let sortKey = this.state.addBibNum + "_" + this.state.addDancerRole + "_" + this.state.addHeat + "_default";//bib_role_heat_judge(default)

    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'add_dancer',
            'partition_key': this.state.addHeat,
            'sort_key': sortKey,
            'bib_number': this.state.addBibNum,
            'dancer_name': this.state.addDancerName,
            'dancer_email': this.state.addDancerEmail,
            'dancer_role': this.state.addDancerRole,
            'heat': this.state.addHeat,
            'judge': 'default',
            'placement': this.state.addPlacement,
            'here':'no',
            'scratch':'no',
            'shakey' : 'no',
            'badged': 'no'
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("add-dancer-data-container");
        const body = document.getElementById("AddDancerResult");
        const div = document.createElement("div");
        div.id = "add-dancer-data-container";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="add-dancer-data">
                    Dancer "{this.state.addBibNum}-{this.state.addDancerName}" added successfully!
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="add-dancer-data">
                    Sorry, something went wrong!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

async function change_dancer_heat() {
    showSpinner("AdminSpinner");

    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'change_dancer_heat',
            'bib_number': this.state.changeHeatBib,
            'new_heat': this.state.changeHeatNew
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("heat-update-data-container");
        const body = document.getElementById("updateHeatResult");
        const div = document.createElement("div");
        div.id = "heat-update-data-container";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="heat-update-data">
                    Heat for dancer "{this.state.changeHeatBib}" updated to heat {this.state.changeHeatNew}!
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="heat-update-data">
                    Sorry, there was an error in the request!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
            hideSpinner("AdminSpinner");
            this.setState({ calcFailure: true });
    });
}

async function change_dancer_name() {
    showSpinner("AdminSpinner");
    
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'update_dancer_name',
            'bib_number': this.state.changeDancerNameBib,
            'new_name': this.state.changeDancerName
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("updateNameDataContainer");
        const body = document.getElementById("updateNameResult");
        const div = document.createElement("div");
        div.id = "updateNameDataContainer";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="updateNameData">
                    Name for dancer "{this.state.changeDancerNameBib}" updated to {this.state.changeDancerName}!
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="updateNameData">
                    Sorry, there was an error in the request!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
            hideSpinner("AdminSpinner");
    });
}

async function change_dancer_role() {
    showSpinner("AdminSpinner");
    
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'update_dancer_role',
            'bib_number': this.state.changeDancerRoleBib,
            'new_role': this.state.changeDancerRole
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("updateRoleDataContainer");
        const body = document.getElementById("updateRoleResult");
        const div = document.createElement("div");
        div.id = "updateRoleDataContainer";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="updateRoleData">
                    Role for dancer "{this.state.changeDancerRoleBib}" updated to {this.state.changeDancerRole}!
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="updateRoleData">
                    Sorry, there was an error in the request!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
            hideSpinner("AdminSpinner");
    });
}

async function remove_dancer_scratch () {
    showSpinner("AdminSpinner");

    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'mark_scratched',
            'bib_number':  this.state.changeScratchBib,
            'undo': true
        })
    }).then(response => {
        return response.json();
    }).then(data => {       
        checkAndRemoveElementById("updateScratchDataContainer");
        const body = document.getElementById("updateScratchResult");
        const div = document.createElement("div");
        div.id = "updateScratchDataContainer";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="updateScratchData">
                    Scratch for dancer "{this.state.changeScratchBib}" has been removed!
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="updateScratchData">
                    Sorry, there was an error in the request!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
            hideSpinner("AdminSpinner");
    });
}

async function get_all_judge_placements_for_dancer() {
    showSpinner("AdminSpinner");
    
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_all_judge_placements_for_dancer',
            'bib_number': this.state.bibSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("bib-search-data-container");
        const body = document.getElementById("dancerDetail");
        const div = document.createElement("div");
        div.id = "bib-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="bib-search-data"> 
                    {Admin.renderDancerDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="bib-search-data">
                    Sorry, dancer "{this.state.bibSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

async function get_all_judge_placements_for_dancer_by_name() {
    showSpinner("AdminSpinner");
    
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_all_judge_placements_for_dancer_by_name',
            'name': this.state.nameSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("name-search-data-container");
        const body = document.getElementById("dancerNameDetail");
        const div = document.createElement("div");
        div.id = "name-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="name-search-data">
                    {Admin.renderDancerNameDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="name-search-data">
                    Sorry, dancer named "{this.state.nameSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

async function shakey_track_placement_export() {
    showSpinner("AdminSpinner");
    
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'shakey_track_placement_export'
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("shaky-data-container");
        const body = document.getElementById("shakeyDetail");
        const div = document.createElement("div");
        div.id = "shaky-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="shaky-data">
                    {Admin.renderShakeyDancePlacementsTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="shaky-data">
                    No potential holds!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

async function track_balance_totals() {
    showSpinner("AdminSpinner");
    
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'track_balance_totals'
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("track-balance-data-container");
        const body = document.getElementById("trackBalanceDetail");
        const div = document.createElement("div");
        div.id = "track-balance-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="track-balance-data">
                    {Admin.renderFinalPlacementCountsTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="track-balance-data">
                    Sorry, no final placements yet!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

async function update_shakey_placements() {
    showSpinner("AdminSpinner");
    
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'update_shakey_placements',
            'bib_number': this.state.overwriteBib,
            'new_placement': this.state.overwritePlacement
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("overwrite-placement-data-container");
        const body = document.getElementById("overwritePlacementResult");
        const div = document.createElement("div");
        div.id = "overwrite-placement-data-container";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="overwrite-placement-data">
                    Dancer {this.state.overwriteBib}'s final placement has been overwritten to {this.state.overwritePlacement}, tap "Get Potiential Holds" again to see the current list of Holds.
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="overwrite-placement-data">
                    Sorry, there was an issue in the request!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

function checkAndRemoveElementById(id) {
    let previousDancerDiv = document.getElementById(id);
    if (previousDancerDiv != null) {
        previousDancerDiv.remove();
    }
}

function showSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.remove("hide");
}

function hideSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.add("hide");
}
export default class Admin extends React.Component {
    csvLink = React.createRef();
    shakeyLink = React.createRef();
    constructor(props) {
        super(props);
        currentuser = this.props.user
        useradminmap.set("sherie_502@yahoo.com", "sherie_carranza");
        useradminmap.set("justin.mann0511@gmail.com", "justin_mann");
        useradminmap.set("alesandrabull@gmail.com", "alesandra_bull");
        useradminmap.set("casey.bayer.9@gmail.com", "casey.bayer");
        useradminmap.set("iadrumman@gmail.com", "noah_price");
        useradminmap.set("crystalembrace@gmail.com", "danielle_ingram");

        this.state = {
            bibSearch: '',
            nameSearch: '',
            updateOldBib: '',
            updateNewBib: '',
            downloadData: [{}],
            shakeyDownloadData: [{}],
            finalDancer: [{}],
            dancerDetailVisible: true,
            calcSuccess: false,
            calcFailure: false,
            addBibNum: '',
            addDancerName: '',
            addDancerEmail: '',
            addDancerRole: '',
            addHeat: '',
            addPlacement: '',
            changeHeatBib: '',
            changeHeatNew: '',
            changeScratchBib: '',
            changeDancerName: '',
            changeDancerRoleBib: '',
            changeDancerRole: '',
            changeDancerNameBib: '',
            overwriteBib: '',
            overwritePlacement: ''
        };
    }

    componentDidMount() {
    }

    render() {
        if (useradminmap.has(currentuser)) {
            return (
                <div className="adminContainer">
                    <h1 className="pageTitle">Admin</h1>
                    <div className="adminButtonContainer">
                        {/* calculate placements button */}
                        <button type="button" className="btn btn-primary adminButton" onClick={calculate_final_placments.bind(this)} >Calculate Final Placements</button>

                        {/* export csv */}
                        <button type="button" className="btn btn-primary adminButton" onClick={get_final_plaments_all.bind(this)}>Export Final Placements</button>

                        {/* this hidden link will be clicked after fetching data */}
                        <CSVLink data={this.state.downloadData} filename={"final-lindyfest-placements.csv"} className="hidden" target="_blank" ref={this.csvLink}></CSVLink>
                        {/* this hidden link will be clicked after fetching data */}

                        <CSVLink data={this.state.shakeyDownloadData} filename={"potiential-holds-dancers.csv"} className="hidden" target="_blank" ref={this.shakeyLink}></CSVLink>
                    </div>
                    <Accordion multiple>
                        <AccordionTab header="Update Dancer">
                            <h4><u>Update bib number</u></h4>
                            <p className="adminInfoText">Use this update option only before final placements are calculated for heats.</p>

                            <label className="adminInputLabel">Old bib number:</label>
                            <InputText className="update-bib-input p-inputtext-sm" value={this.state.updateOldBib} onChange={(e) => this.setState({ updateOldBib: e.target.value })} />
                            <label className="adminInputLabel">New bib number:</label>
                            <InputText className="update-bib-input p-inputtext-sm" value={this.state.updateNewBib} onChange={(e) => this.setState({ updateNewBib: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={update_bib_number.bind(this)}>Update</button>
                            <div id="updateBibResult"></div>
                            
                            <hr />

                            <h4><u>Change dancer heat</u></h4>
                            <p className="adminInfoText">Use this update option only before final placements are calculated for heats.</p>

                            <label className="adminInputLabel">Bib number:</label>
                            <InputText className="update-heat-input p-inputtext-sm" value={this.state.changeHeatBib} onChange={(e) => this.setState({ changeHeatBib: e.target.value })} />
                            <label className="adminInputLabel">New heat number:</label>
                            <InputText className="update-heat-input p-inputtext-sm" value={this.state.changeHeatNew} onChange={(e) => this.setState({ changeHeatNew: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={change_dancer_heat.bind(this)}>Update</button>
                            <div id="updateHeatResult"></div>

                            <hr />

                            <h4><u>Remove dancer scratch</u></h4>
                            <label className="adminInputLabel">Bib number:</label>
                            <InputText className="update-heat-input p-inputtext-sm" value={this.state.changeScratchBib} onChange={(e) => this.setState({ changeScratchBib: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={remove_dancer_scratch.bind(this)}>Update</button>
                            <div id="updateScratchResult"></div>

                            <hr />
                            <h4><u>Update dancer name</u></h4>
                            <label className="adminInputLabel">Bib number:</label>
                            <InputText className="update-heat-input p-inputtext-sm" value={this.state.changeDancerNameBib} onChange={(e) => this.setState({ changeDancerNameBib: e.target.value })} />
                            <label className="adminInputLabel">New dancer name:</label>
                            <InputText className="update-heat-input p-inputtext-sm" value={this.state.changeDancerName} onChange={(e) => this.setState({ changeDancerName: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={change_dancer_name.bind(this)}>Update</button>
                            <div id="updateNameResult"></div>

                            <hr />
                            <h4><u>Update dancer role</u></h4>
                            <label className="adminInputLabel">Bib number:</label>
                            <InputText className="update-heat-input p-inputtext-sm" value={this.state.changeDancerRoleBib} onChange={(e) => this.setState({ changeDancerRoleBib: e.target.value })} />
                            <label className="adminInputLabel">New dancer role:</label>
                            <InputText className="update-heat-input p-inputtext-sm" value={this.state.changeDancerRole} onChange={(e) => this.setState({ changeDancerRole: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={change_dancer_role.bind(this)}>Update</button>
                            <div id="updateRoleResult"></div>
                        </AccordionTab>
                        <AccordionTab header="Add New Dancer">
                            <p className="adminInfoText">Use this update option to add a new dancer. If the dancer was already in the system and lost their bib, use "Update Bib Number" above.</p>

                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Bib number:</label>
                                <InputText className="update-bib-input p-inputtext-sm" value={this.state.addBibNum} onChange={(e) => this.setState({ addBibNum: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Heat number:</label>
                                <InputText className="update-bib-input p-inputtext-sm" value={this.state.addHeat} onChange={(e) => this.setState({ addHeat: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Dancer name:</label>
                                <InputText className="update-bib-input p-inputtext-sm" value={this.state.addDancerName} onChange={(e) => this.setState({ addDancerName: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Dancer email:</label>
                                <InputText className="update-bib-input p-inputtext-sm" value={this.state.addDancerEmail} onChange={(e) => this.setState({ addDancerEmail: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Dancer Role:</label>
                                <InputText className="update-bib-input p-inputtext-sm" value={this.state.addDancerRole} onChange={(e) => this.setState({ addDancerRole: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Placement:</label>
                                <InputText className="update-bib-input p-inputtext-sm" value={this.state.addPlacement} onChange={(e) => this.setState({ addPlacement: e.target.value })} />
                            </div>
                            <button type="button" className="btn btn-primary adminButton" onClick={add_dancer.bind(this)}>Add Dancer</button>

                            <div id="AddDancerResult"></div>

                        </AccordionTab>
                        <AccordionTab header="Search for Dancer">
                            <div>
                                <h5>Search by bib number</h5>
                                <label className="adminInputLabel">Bib number:</label>
                                <InputText className="p-inputtext-sm" value={this.state.bibSearch} onChange={(e) => this.setState({ bibSearch: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={get_all_judge_placements_for_dancer.bind(this)}>Search</button>

                                <div id="dancerDetail">
                                </div>

                                <hr />

                                <h5>Search by dancer name</h5>
                                <label className="adminInputLabel">Dancer Name:</label>
                                <InputText className="p-inputtext-sm" value={this.state.nameSearch} onChange={(e) => this.setState({ nameSearch: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={get_all_judge_placements_for_dancer_by_name.bind(this)}>Search</button>

                                <div id="dancerNameDetail">
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Potiential Holds Dancers">
                            <div>
                                <h5>Overwrite Final Placements</h5>
                                <label className="adminInputLabel">Bib Number:</label>
                                <InputText className="update-bib-input p-inputtext-sm" value={this.state.overwriteBib} onChange={(e) => this.setState({ overwriteBib: e.target.value })} />
                                <label className="adminInputLabel">New Placement:</label>
                                <InputText className="update-bib-input p-inputtext-sm" value={this.state.overwritePlacement} onChange={(e) => this.setState({ overwritePlacement: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={update_shakey_placements.bind(this)}>Overwrite Final Placement</button>
                                <div id="overwritePlacementResult"></div>

                                <hr />

                                <h5>View Potiential Holds Dancers</h5>
                                <button type="button" className="btn btn-primary adminButton" onClick={shakey_track_placement_export.bind(this)}>Get Potiential Holds</button>

                                {/* export csv */}
                                <button type="button" className="btn btn-primary adminButton" onClick={track_placement_holds_export.bind(this)}>Export Potential Hold Placements</button>

                                <div id="shakeyDetail">
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="View Final Placement Counts">
                            <div>
                                <p className="adminInfoText">Use this to get counts for all tracks, including potiential hold dancers.</p>
                                <p className="adminInfoText">Targets: 6 - 40 dancers, 20 couples</p>
                                <p className="adminInfoText">5 - 60 dancers, 30 couples</p>
                                <p className="adminInfoText">4 - 80 dancers, 40 couples</p>
                                <p className="adminInfoText">3 - 60 dancers, 30 couples</p>
                                <button type="button" className="btn btn-primary adminButton" onClick={track_balance_totals.bind(this)}>Get Final Counts</button>

                                <div id="trackBalanceDetail">
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>

                    {/************************** hidden dialog boxes **************************/}
                    <Dialog header="Success" visible={this.state.calcSuccess} style={{ width: '50vw' }} onHide={() => this.setState({ calcSuccess: false })}>
                        <p className="m-0">
                            Final placements have been calculated successfully!
                        </p>
                    </Dialog>
                    <Dialog header="Failure" visible={this.state.calcFailure} style={{ width: '50vw' }} onHide={() => this.setState({ calcFailure: false })}>
                        <p className="m-0">
                            There was an error in the response!
                        </p>
                    </Dialog>
                    <div id="AdminSpinner" className="spinner-parent hide">
                        <div className="spinner-container justify-content-center">
                            <ProgressSpinner />
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className='body-container'>Not an Admin you are! </div>
            );
        }
    }

    static renderDancerDetailTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" ></Column>
                    <Column field="dancer_name" header="Name" ></Column>
                    <Column field="judge" header="Placement Type" sortable></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Placement" sortable></Column>
                    <Column field="heat" header="Heat" sortable></Column>
                    <Column field="shakey" header="Hold?" sortable></Column>
                    <Column field="here" header="Here?" sortable></Column>
                    <Column field="scratch" header="Scratched?" sortable></Column>
                </DataTable>
            </div>
        );
    }
    static renderDancerNameDetailTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" sortable></Column>
                    <Column field="dancer_name" header="Name" sortable></Column>
                    <Column field="judge" header="Placement Type" sortable></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Placement" sortable></Column>
                    <Column field="heat" header="Heat" sortable></Column>
                    <Column field="shakey" header="Hold?" sortable></Column>
                    <Column field="here" header="Here?" sortable></Column>
                    <Column field="scratch" header="Scratched?" sortable></Column>
                </DataTable>
            </div>
        );
    }

    static renderShakeyDancePlacementsTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" sortable></Column>
                    <Column field="dancer_name" header="Name" sortable></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Placement" sortable></Column>
                    <Column field="all_placements" header="All Placement"></Column>
                    <Column field="heat" header="Heat" sortable></Column>
                    <Column field="shakey" header="Hold?" sortable></Column>
                    <Column field="here" header="Here?" sortable></Column>
                    <Column field="scratch" header="Scratched?" sortable></Column>
                </DataTable>
            </div>
        );
    }

    static renderFinalPlacementCountsTable(tracks) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={tracks} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="Track" header="Track" sortable></Column>
                    <Column field="Leaders" header="Leaders"></Column>
                    <Column field="Followers" header="Followers"></Column>
                </DataTable>
            </div>
        );
    }
}