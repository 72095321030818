import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from "primereact/inputtext";
import ReactDOM from "react-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';

let currentuser = "default";
const badgeReadyButtonStyle = "btn badge-create";
const userBadgeCreatemap = new Map();

function showSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.remove("hide");
}
function hideSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.add("hide");
}
function checkAndRemoveElementById(id) {
    let previousDancerDiv = document.getElementById(id);
    if (previousDancerDiv != null) {
        previousDancerDiv.remove();
    }
}
async function get_final_placements_for_a_dancer() {
    showSpinner("BadgeCreateSpinner");
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_final_placements_for_a_dancer',
            'bib_number': this.state.bibSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("bib-search-data-container");
        const body = document.getElementById("dancerDetail");
        const div = document.createElement("div");
        div.id = "bib-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="bib-search-data">
                    {BadgeCreate.renderDancerDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="bib-search-data">
                    Sorry, dancer "{this.state.bibSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("BadgeCreateSpinner");
    }).catch((error) => {
        hideSpinner("BadgeCreateSpinner");
        console.debug(error);
    });
}

async function get_final_placements_for_a_dancer_by_name() {
    showSpinner("BadgeCreateSpinner");
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_final_placements_for_a_dancer_by_name',
            'name': this.state.nameSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("name-search-data-container");
        const body = document.getElementById("dancerNameDetail");
        const div = document.createElement("div");
        div.id = "name-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="name-search-data">
                    {BadgeCreate.renderDancerDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="name-search-data">
                    Sorry, dancer named "{this.state.nameSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("BadgeCreateSpinner");
    }).catch((error) => {
        hideSpinner("BadgeCreateSpinner");
        console.debug(error);
    });
}
async function onBadgeReady(value, dancer) {
    showSpinner("BadgeCreateSpinner");
    const response = await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'send_placement_email',
            'bib_number': dancer.bib_number,
            'dancer_name': dancer.dancer_name,
            'placememt': dancer.placement,
            'to_email': dancer.dancer_email
        })
    }).then(response => {
        var element = document.getElementById(dancer.bib_number);
        if (element !== null && element !== undefined) {
            element.parentElement.parentElement.classList.add("dancer_emailed");
        }

        var btnElement = document.getElementById("badgeBtn-" + dancer.bib_number);
        if (btnElement !== null && btnElement !== undefined) {
            btnElement.classList.add("hide");
        }

        hideSpinner("BadgeCreateSpinner");
    }).catch((error) => {
        hideSpinner("BadgeCreateSpinner");
        console.debug(error);
    });
}

export default class BadgeCreate extends React.Component {
    constructor(props) {
        super(props);
        currentuser = this.props.user;

        userBadgeCreatemap.set("sherie_502@yahoo.com", "sherie_carranza");
        userBadgeCreatemap.set("justin.mann0511@gmail.com", "justin_mann");
        userBadgeCreatemap.set("alesandrabull@gmail.com", "alesandra_bull");
        userBadgeCreatemap.set("casey.bayer.9@gmail.com", "casey.bayer");
        userBadgeCreatemap.set("iadrumman@gmail.com", "noah_price");
        userBadgeCreatemap.set("crystalembrace@gmail.com", "danielle_ingram");

        this.state = {
            bibSearch: '',
            finalDancers: [{ bib_number: '000' }],
            loading: +true,
            nameSearch: ''
        };
    }

    async componentDidMount() {
        const response = await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
            crossDomain: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Connection': 'keep-alive'
            },
            body: JSON.stringify({
                'method_name': 'get_final_plaments_all_badge',
            })
        });
        const dancers = await response.json();
        const sortedDancers = dancers.sort((a, b) => a.dancer_name.localeCompare(b.dancer_name));
        this.setState({ finalDancers: sortedDancers, loading: false });
    }

    render() {
        if (userBadgeCreatemap.has(currentuser)) {
            let contents = +this.state.loading
                ? <p><em>Loading table data...</em></p>
                : BadgeCreate.renderAllFinalPlacementsTable(this.state.finalDancers);
            return (
                <div className="adminContainer">
                    <h1 className="pageTitle">Badge create view</h1>
                    <Accordion multiple>
                        <AccordionTab header="Search for Dancer">
                            <div>
                                <h5>Search by bib number</h5>
                                <label className="adminInputLabel">Bib number:</label>
                                <InputText className="p-inputtext-sm" value={this.state.bibSearch} onChange={(e) => this.setState({ bibSearch: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={get_final_placements_for_a_dancer.bind(this)}>Search</button>

                                <div id="dancerDetail">
                                </div>

                                <hr />

                                <h5>Search by dancer name</h5>
                                <label className="adminInputLabel">Dancer Name:</label>
                                <InputText className="p-inputtext-sm" value={this.state.nameSearch} onChange={(e) => this.setState({ nameSearch: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={get_final_placements_for_a_dancer_by_name.bind(this)}>Search</button>

                                <div id="dancerNameDetail">
                                </div>
                            </div>
                        </AccordionTab>


                    </Accordion>
                    <div className="BadgeCreatePlacementsContainer">
                        {contents}
                    </div>

                    <div id="BadgeCreateSpinner" className="spinner-parent hide">
                        <div className="spinner-container justify-content-center">
                            <ProgressSpinner />
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className='body-container'>You can't create a badge! </div>
            );
        }
    }

    static renderDancerDetailTable(dancers) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancers} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" ></Column>
                    <Column field="dancer_name" header="Name" ></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Final Placement" sortable></Column>
                    <Column field="judge" header="" body={BadgeCreate.badgeReadyButton}></Column>
                </DataTable>
            </div>
        );
    }

    static renderAllFinalPlacementsTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex" paginator rows={20}>
                    <Column field="bib_number" header="Bib" ></Column>
                    <Column field="dancer_name" header="Name" sortable></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Final Placement" sortable></Column>
                    <Column field="judge" header="" body={BadgeCreate.badgeReadyButton}></Column>
                </DataTable>
            </div>
        );
    }

    static badgeReadyButton(dancer) {
        if (dancer.badged === "yes") {
            return (
                <div>
                    Badge already created!
                </div>
            );
        }
        else {
            return (
                <div id={dancer.bib_number} className="gap-3">
                    <button id={"badgeBtn-" + dancer.bib_number} type="button" className={badgeReadyButtonStyle} onClick={e => onBadgeReady(e, dancer)}>badge ready</button>
                </div>
            );
        }
    }
}
