import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from "primereact/inputtext";
import ReactDOM from "react-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';

function showSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.remove("hide");
}
function hideSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.add("hide");
}
function checkAndRemoveElementById(id) {
    let previousDancerDiv = document.getElementById(id);
    if (previousDancerDiv != null) {
        previousDancerDiv.remove();
    }
}


async function get_final_placements_for_a_dancer() {
    showSpinner("HomeSpinner");
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_final_placements_for_a_dancer',
            'bib_number': this.state.bibSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("bib-search-data-container");
        const body = document.getElementById("dancerDetail");
        const div = document.createElement("div");
        div.id = "bib-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="bib-search-data">
                     {Home.renderDancerDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="bib-search-data">
                    Sorry, dancer "{this.state.bibSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("HomeSpinner");
    }).catch((error) => {
        hideSpinner("HomeSpinner");
        console.debug(error);
    });
}

async function get_final_placements_for_a_dancer_by_name() {
    showSpinner("HomeSpinner");
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_final_placements_for_a_dancer_by_name',
            'name': this.state.nameSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("name-search-data-container");
        const body = document.getElementById("dancerNameDetail");
        const div = document.createElement("div");
        div.id = "name-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="name-search-data">
                {Home.renderDancerDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="name-search-data">
                    Sorry, dancer named "{this.state.nameSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("HomeSpinner");
    }).catch((error) => {
        hideSpinner("HomeSpinner");
        console.debug(error);
    });
}
// async function get_all_placements_for_a_dancer() {
//     showSpinner("HomeSpinner");

//     await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
//         crossDomain: true,
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Connection': 'keep-alive'
//         },
//         body: JSON.stringify({
//             'method_name': 'get_all_judge_placements_for_dancer',
//             'bib_number': this.state.bibSearch
//         })
//     }).then(response => {
//         return response.json();
//     }).then(data => {
//         checkAndRemoveElementById("bib-search-data-container");
//         const body = document.getElementById("dancerDetail");
//         const div = document.createElement("div");
//         div.id = "bib-search-data-container";
//         body.appendChild(div);

//         if (data.length !== 0) {
//             ReactDOM.render(
//                 <div id="bib-search-data">
//                     {Home.renderDancerDetailTable(data)}
//                 </div>, div
//             );
//         }
//         else {
//             ReactDOM.render(
//                 <div className="resultMessage" id="bib-search-data">
//                     Sorry, dancer "{this.state.bibSearch}" not found!
//                 </div>, div
//             );
//         }

//         hideSpinner("HomeSpinner");
//     }).catch((error)=>{        
//         hideSpinner("HomeSpinner"); 
//         console.debug(error);
//     });
// }

// async function get_all_placements_for_a_dancer_by_name() {
//     showSpinner("HomeSpinner");
    
//     await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
//         crossDomain: true,
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Connection': 'keep-alive'
//         },
//         body: JSON.stringify({
//             'method_name': 'get_all_judge_placements_for_dancer_by_name',
//             'name': this.state.nameSearch
//         })
//     }).then(response => {
//         return response.json();
//     }).then(data => {
//         checkAndRemoveElementById("name-search-data-container");
//         const body = document.getElementById("dancerNameDetail");
//         const div = document.createElement("div");
//         div.id = "name-search-data-container";
//         body.appendChild(div);

//         if (data.length !== 0) {
//             ReactDOM.render(
//                 <div id="name-search-data">
//                     {Home.renderDancerDetailTable(data)}
//                 </div>, div
//             );
//         }
//         else {
//             ReactDOM.render(
//                 <div className="resultMessage" id="name-search-data">
//                     Sorry, dancer named "{this.state.nameSearch}" not found!
//                 </div>, div
//             );
//         }

//         hideSpinner("HomeSpinner");
//     }).catch((error)=>{        
//         hideSpinner("HomeSpinner"); 
//         console.debug(error);
//     });
// }
async function get_final_plaments_all() {
    showSpinner("HomeSpinner");
    
    await fetch('https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend', {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_final_plaments_all',
        })
    }).then(response => {
        let data = response.json();
        return data;
    }).then(data => {
        checkAndRemoveElementById("all-final-data-container");
        const body = document.getElementById("allfinal");
        const div = document.createElement("div");
        div.id = "all-final-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="final-search-data">
                    {Home.renderFinalDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="final-search-data">
                    Sorry, no final placements!
                </div>, div
            );
        }

        hideSpinner("HomeSpinner");
    }).catch((error)=>{        
        hideSpinner("HomeSpinner"); 
        console.debug(error);
    });
}

export default class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bibSearch: '',
            nameSearch: '',
        };
    }

    componentDidMount() {
    }

    render() {        
        return (
            <div className="adminContainer">
                <h1 className="pageTitle">Welcome to Lindyfest placements</h1>
                <Accordion multiple>
                    <AccordionTab header="Search for Dancer">
                        <div>
                            <h5>Search by bib number</h5>
                            <label className="adminInputLabel">Bib number:</label>
                            <InputText className="p-inputtext-sm" value={this.state.bibSearch} onChange={(e) => this.setState({ bibSearch: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={get_final_placements_for_a_dancer.bind(this)}>Search</button>

                            <div id="dancerDetail">
                            </div>

                            <hr />

                            <h5>Search by dancer name</h5>
                            <label className="adminInputLabel">Dancer Name:</label>
                            <InputText className="p-inputtext-sm" value={this.state.nameSearch} onChange={(e) => this.setState({ nameSearch: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={get_final_placements_for_a_dancer_by_name.bind(this)}>Search</button>

                            <div id="dancerNameDetail">
                            </div>
                        </div>
                    </AccordionTab>
                    
                    <AccordionTab header="See final placements for all dancers">
                        <div>
                            <button type="button" className="btn btn-primary adminButton" onClick={get_final_plaments_all.bind(this)}>View all Final Placements</button>
                            <div id="allfinal">
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
                <div className="HeatInfo">                    
                    Please see below for the estimated time of track placement heats:<br />
                        <div className="HeatSessionInfo">
                            Session A 9-9:45 am <br />
                            9:00 Heat 1<br />
                            9:15 Heat 2<br />
                            9:30 Heat 3<br />
                            <br />
                            Session B 10-11 am<br />
                            10:00 Heat 4<br />
                            10:15 Heat 5<br />
                            10:30 Heat 6<br />
                            10:45 Heat 7<br />
                            <br />
                            Session C 11:15-12 pm<br />
                            11:15 Heat 8<br />
                            11:30 Heat 9<br />
                            11:45 Heat 10<br />
                        </div>
                </div>
                <div className="disputeInfo">
                    The Grand Pavillion will be open from 9-9:30 am on Saturday if you missed friday track placements and 9:30-10:00am if you have questions on your track placement. 
                </div>
                <div className="BadgeInfo">
                    Dancers will be able to pick up track badges from 12 - 1:30pm at the check-in desk. You should receive an email if your badge is ready early. 
                </div>
                <div id="HomeSpinner" className="spinner-parent hide">
                    <div className="spinner-container justify-content-center">
                        <ProgressSpinner />
                    </div>
                </div>
            </div>
        );
    }

    static renderDancerDetailTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" ></Column>
                    <Column field="dancer_name" header="Name" ></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Final placement" sortable></Column>
                    {/* <Column field="judge" header="Final placement?" sortable body={Home.FinalPlacement}></Column> */}
                    <Column field="heat" header="Heat" sortable></Column>
                </DataTable>
            </div>
        );
    }
    
    static renderFinalDetailTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" ></Column>
                    <Column field="dancer_name" header="Name" ></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Final Placement" sortable></Column>
                    <Column field="heat" header="Heat" sortable></Column>
                </DataTable>
            </div>
        );
    }

    static FinalPlacement(dancer) {
        let finalPlacement = dancer.judge === "final"? "yes" : "no";
        return (
            <div>
                {finalPlacement}
            </div>
        );
    }
}
